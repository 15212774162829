export default () => ({
    tableCols: {},
    tableList: {},
    tableData: {},
    focusCache: {},
    sortModel: {},
    tableColsRules: {},
    tableUpdate: {},
    tableUpdateSelect: {},
    tableRowFocus: {},
    tablesInfo: {}
})