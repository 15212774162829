<template>
    <div id="app">
        <router-view/>
        <template v-if="checkDrawerShow">
            <template v-if="config && config.injectInit && config.injectInit.length">
                <initSwitch
                    v-for="(folder, index) in config.injectInit"
                    :key="index"
                    :folder="folder" />
            </template>
            <UserSettings v-if="user" />
            <component :is="setPassword" />
            <component v-if="serverType === 'dev'" :is="UIComponentsDrawer" />
            <component :is="commandButton" />
        </template>
        <NetworkStatus v-if="!online" />
        <vue-progress-bar></vue-progress-bar>
    </div>
</template>

<script>
import 'moment/locale/ru'
import { mapState } from 'vuex'
import launchQueue from '@/mixins/launchQueue'
export default {
    mixins: [launchQueue],
    sockets: {
        connect() {
            console.log('%c Socket connected 🤝', 'color: #1d65c0')
        },
        chat_online_user(data) {
            this.$store.commit('user/SET_ONLINE_USER', data)
        },
        chat_offline_user(data) {
            this.$store.commit('user/SET_OFFLINE_USER', data)
        }
    },
    metaInfo() {
        return {
            title: this.siteName
        }
    },
    components: {
        initSwitch: () => import('@/components/initSwitch'),
        UserSettings: () => import('@/components/UserSettings'),
        NetworkStatus: () => import('@/components/NetworkStatus')
    },
    computed: {
        ...mapState({
            config: state => state.config.config,
            online: state => state.online,
            user: state => state.user.user,
            serverType: state => state.serverType
        }),
        checkDrawerShow() {
            if(this.$route.name !== 'page_404' 
            && this.$route.name !== 'login' 
            && this.$route.name !== 'forgotPassword' 
            && this.$route.name !== 'resetPassword' 
            && this.$route.name !== 'joinUser'
            && this.$route.name !== 'forgotPasswordEmail' 
            && this.$route.name !== 'forgotPasswordPhone'
            && this.$route.name !== 'registration') {
                return true
            } else
                return false
        },
        UIComponentsDrawer() {
            return this.serverType === 'dev' ? () => import('@/components/UIDrawer.vue') : null
        },
        siteName() {
            if(this.config?.site_setting?.site_name)
                return this.config.site_setting.site_name
            else
                return 'E-HUB'
        },
        setPassword() {
            if(this.user?.password_generated)
                return () => import(/* webpackMode: "lazy" */'@/components/PasswordSet.vue')
            else
                return null
        },
        commandButton() {
            return false
            if(this.config?.footer_setting?.show_hd_button)
                return () => import(/* webpackMode: "lazy" */'@/components/CommandButton')
            return false
        }
    },
    created() {
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault()
            this.$store.commit('SET_PWA_POPUP', e)
        })
    },
    mounted() {
        this.$moment.locale('ru')
        this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)
        this.$store.commit('UPDATE_WINDOW_HEIGHT', window.innerHeight)

        document.addEventListener("visibilitychange", () => {
            if (document.hidden)
                this.$store.commit('TOGGLE_VISIBILITY_STATE', false)
            else
                this.$store.commit('TOGGLE_VISIBILITY_STATE', true)
        })

        window.addEventListener('online', () => this.$store.commit('TOGGLE_ONLINE', true))
        window.addEventListener('offline', () => this.$store.commit('TOGGLE_ONLINE', false))

        let resizeId;
        window.addEventListener('resize', () => {
            clearTimeout(resizeId)
            resizeId = setTimeout(() => {
                this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)
                this.$store.commit('UPDATE_WINDOW_HEIGHT', window.innerHeight)
            }, 150)
        })
    }
}
</script>