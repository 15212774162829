const routes = [
    {
        path: 'list',
        name: 'news-front',
        component: () => import('./NewsFront.vue'),
        isHidden: true,
        meta: {
            title: "Новости"
        }
    },
    {
        path: 'list/:id',
        name: 'news-full',
        component: () => import('./NewsDetail.vue'),
        isHidden: true,
        meta: {
            title: "Новости"
        }
    }
]

export default routes