import { register } from 'register-service-worker'
import store from '@/store'

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready () {
            console.log(
                'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
            )
        },
        registered () {
            console.log('%c Service worker has been registered ✅', 'color: #52c41a')
        },
        cached () {
            console.log('%c Content has been cached for offline use. 📥', 'color: #7367f0')
        },
        updatefound () {
            console.log('%c New content is downloading. 🤘', 'color: #7367f0')
        },
        updated (registration) {
            console.log('%c New content is available; please refresh. ♻️', 'color: #7367f0')
            store.commit('SET_SW_REG', registration)
            store.commit('SET_SW_UPDATE', true)
        },
        offline () {
            console.log('%c No internet connection found. App is running in offline mode. ⛔️', 'color: #7367f0')
        },
        error (error) {
            console.error('Error during service worker registration:', error)
        }
    })
}